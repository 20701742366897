<template>
	<section>
		<b-row style="justify-content: space-between" class="mb-2 align-center">
			<b-col>
				<Breadcrumb :labels="labels" />
			</b-col>
			<b-col>
				<div class="icon-search-wrapper ml-auto" style="max-width: 346px; width: 100%;">
					<b-form-group class="mb-0">
						<b-input-group class="input-group-merge">
							<b-input-group-prepend is-text>
								<feather-icon icon="SearchIcon" size="17" />
							</b-input-group-prepend>
							<b-form-input type="text" placeholder="Search" @input="searchValue" />
						</b-input-group>
					</b-form-group>
				</div>
			</b-col>
		</b-row>
		<template v-if="(userData && permission.create)">
			<b-button type="button" variant="primary" class="btn-df size-18 black cursor-pointer d-block ml-auto"
				style="background-color: #8471FF !important; z-index: 5; margin-bottom: -50px; position: relative "
				@click="addNew">
				Add FY
			</b-button>
		</template>
		<template v-else>
			<b-button type="button" variant="primary" class="btn-df size-18 black d-block ml-auto btn-disabled-gray"
				disabled style="z-index: 5; margin-bottom: -50px; position: relative ">
				Add FY
			</b-button>
		</template>
		<b-row>
			<b-col md="12">
				<div>
					<b-tabs card class="caption-add table-custom">
						<b-tab title="Strategic Plans" active class="overview-tab num-width">
							<b-card class="px-table-0 mt-1 w-20-percent-cl-4">
								<b-row class="align-center">
									<b-col lg="6" style="padding-left: 33px ">
										<h2 class="mb-2"><strong>Financial Year Listing</strong></h2>
									</b-col>
								</b-row>
								<b-table small :fields="fields" :items="items"
									tbody-tr-class="size-14 table-row cursor-pointer" @row-clicked="detail" show-empty>
									<template #head(id)="data">
										<span>{{ data.label }}</span>
									</template>
									<template #head()="data">
										<span class="d-block text-center" style="text-transform: none;">{{ data.label
										}}</span>
									</template>
									<template #cell(name)="data">
										<span class="text-center">{{ data.item.name }}</span>
									</template>
									<template #cell(id)="data">
										<span>{{ data.value }}</span>
									</template>
									<template #cell(status)="data">
										<span class="d-block text-center" style="color: #8471FF">{{ data.item.status
										}}</span>
									</template>
									<template #cell(action)="data">
										<div class="d-block text-center">
											<template v-if="(userData && permission.edit)">
												<span class="px-05 cursor-pointer" @click.stop="edit(data.item.id)">
													<svg width="19" height="19" viewBox="0 0 19 19" fill="none"
														xmlns="http://www.w3.org/2000/svg">

														<path
															d="M1.41999 18.5792C1.13948 18.5787 0.872062 18.4604 0.682993 18.2532C0.490439 18.0476 0.394758 17.7697 0.419993 17.4892L0.664993 14.7951L11.983 3.48115L15.52 7.01715L4.20499 18.3302L1.51099 18.5752C1.47999 18.5782 1.44899 18.5792 1.41999 18.5792ZM16.226 6.31015L12.69 2.77415L14.811 0.65315C14.9986 0.465373 15.2531 0.359863 15.5185 0.359863C15.7839 0.359863 16.0384 0.465373 16.226 0.65315L18.347 2.77415C18.5348 2.96172 18.6403 3.21624 18.6403 3.48165C18.6403 3.74706 18.5348 4.00158 18.347 4.18915L16.227 6.30915L16.226 6.31015Z"
															fill="#8471FF" />
													</svg>
												</span>
											</template>
											<template v-else>
												<span class="px-05">
													<svg width="19" height="19" viewBox="0 0 19 19" fill="none"
														xmlns="http://www.w3.org/2000/svg">

														<path
															d="M1.41999 18.5792C1.13948 18.5787 0.872062 18.4604 0.682993 18.2532C0.490439 18.0476 0.394758 17.7697 0.419993 17.4892L0.664993 14.7951L11.983 3.48115L15.52 7.01715L4.20499 18.3302L1.51099 18.5752C1.47999 18.5782 1.44899 18.5792 1.41999 18.5792ZM16.226 6.31015L12.69 2.77415L14.811 0.65315C14.9986 0.465373 15.2531 0.359863 15.5185 0.359863C15.7839 0.359863 16.0384 0.465373 16.226 0.65315L18.347 2.77415C18.5348 2.96172 18.6403 3.21624 18.6403 3.48165C18.6403 3.74706 18.5348 4.00158 18.347 4.18915L16.227 6.30915L16.226 6.31015Z"
															fill="#b1b1b1" />
													</svg>
												</span>
											</template>
											<template v-if="userData && permission.delete">
												<span class="px-05 cursor-pointer"
													@click.stop="showDeleteAction(data.item.id, data.item.name)">
													<svg width="14" height="18" viewBox="0 0 14 18" fill="none"
														xmlns="http://www.w3.org/2000/svg">
														<path fill-rule="evenodd" clip-rule="evenodd"
															d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z"
															fill="#DD3E47" />
													</svg>
												</span>
											</template>
											<template v-else>
												<span class="px-05">
													<svg width="14" height="18" viewBox="0 0 14 18" fill="none"
														xmlns="http://www.w3.org/2000/svg">
														<path fill-rule="evenodd" clip-rule="evenodd"
															d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z"
															fill="#b1b1b1" />
													</svg>
												</span>
											</template>
										</div>
									</template>
									<template #cell()="data">
										<span class="d-block text-center">{{ data.value }}</span>
									</template>
									<template #empty="scope">
										<template v-if="getsearch">
											<h4 class="my-3 text-center"
												style="color: #CFCFCF !important; font-size: 14px !important">No data found
											</h4>
										</template>
										<template v-else>
											<h4 class="my-3 text-center"
												style="color: #CFCFCF !important; font-size: 14px !important">No data</h4>
										</template>
									</template>
								</b-table>
							</b-card>
						</b-tab>
						<div v-if="this.total > 0" class="paging-cus relative">
							<b-pagination v-model="currentPage" :total-rows="total" :per-page="perPage"
								aria-controls="custom-table" first-number last-number class="just-center"></b-pagination>
							<div class="limit-per">
								<select v-model="selected" class="cursor-pointer">
									<option v-for="limit in limitpage">{{ limit }}</option>
								</select>
							</div>
						</div>
					</b-tabs>
				</div>
			</b-col>
		</b-row>
	</section>
</template>

<script>

import Breadcrumb from '../partial/Breadcrumb';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import globalAdmin from '../model/globalAdmin';
export default {
	mixins: [globalAdmin],
	components: {
		Breadcrumb,
		ToastificationContent
	},
	data() {
		return {
			userData: JSON.parse(localStorage.getItem('userData')),
			labels: { "itees-strategic": "" },
			fields: [
				{ key: 'id', label: 'ID.' },
				{ key: 'start_year', label: 'FY Start' },
				{ key: 'end_year', label: 'FY End' },
				{ key: 'name', label: 'Name' },
				{ key: 'status', label: 'Status' },
				{ key: 'total_programme_developing', label: 'Developing Program' },
				{ key: 'total_programme_launched', label: 'Launched Program' },
				{ key: 'total_programme_yet_to_launch', label: 'Yet to Launch' },
				{ key: 'action', label: 'Actions' },
			],
			permission: [],
			items: [],
			getsearch: null,
			timer: null,

			perPage: 10,
			currentPage: 1,
			total: 0,
			limitpage: ['10', '20', '30'],
			selected: '10'
		}
	},
	watch: {
		getsearch(val) {
			this.fetchList(val)
		},
		currentPage(val) {
			this.fetchList(this.getsearch)
		},
		selected(val) {
			if (val) {
				this.perPage = val
				this.fetchList(this.getsearch)
			}
		}
	},
	mounted() {
		this.selected = '10'
	},
	created() {
		this.permission = this.checkPermission(this.userData, "Strategic Plan")
		// console.log(this.permission, '999999999999')
		this.fetchList(this.getsearch)
	},
	methods: {
		detail(item) {
			this.$router.push(`/admin/itees-strategic-plan/${item.id}`)
		},
		edit(id) {
			this.$router.push(`/admin/itees-strategic-plan/edit/${id}`)
		},
		addNew() {
			this.$router.push(`/admin/itees-strategic-plan/add-fy`)
		},
		fetchList(valueSearch) {
			this.$store
				.dispatch('project/fetchStrategicPlan', {
					search: valueSearch,
					limit: this.perPage,
					page: this.currentPage,
				})
				.then(response => {
					this.items = response.data.data.data
					this.total = response.data.data.total
					// console.log(response.data.data.data)
				})
				.catch((error) => {
					console.log(error)
				})
		},
		searchValue(val) {
			if (this.timer) {
				clearTimeout(this.timer);
				this.timer = null;
			}
			this.timer = setTimeout(() => {
				this.getsearch = val
			}, 200);
		},
		showDeleteAction(id, name) {
			this.$bvModal.msgBoxConfirm('Are you sure you want to delete this item?', {
				title: 'Delete',
				size: 'sm',
				buttonSize: 'sm',
				okVariant: 'danger',
				okTitle: 'Delete',
				cancelTitle: 'Cancel',
				cancelVariant: 'outline-danger',
				footerClass: 'p-2 modal-delete',
				hideHeaderClose: false,
				centered: true
			})
				.then(value => {
					if (value) {
						this.deleteItem(id, name)
					}
				})
				.catch(err => {
					// An error occurred
				})
		},
		deleteItem(id, title) {
			this.$store
				.dispatch('project/deleteStrategicPlan', {
					id: id
				})
				.then(response => {
					this.$toast({
						component: ToastificationContent,
						props: {
							title: title + ' Has been deleted!',
							variant: 'danger',
						},
					},
						{
							position: 'top-center'
						}
					)
					this.fetchList(this.getsearch)
				})
				.catch((error) => {
					console.log(error)
					this.$toast({
						component: ToastificationContent,
						props: {
							title: error.response.data.message,
							variant: 'danger',
						},
					},
						{
							position: 'top-center'
						})
				})
		},
	}
};

</script>

<style lang="scss">@import "@/assets/scss/_ite_tab_table.scss";

.Vue-Toastification__container.top-center {
	top: 20px;
}</style>